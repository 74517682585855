@import url(https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap);
:root {
  --white: #fcf2e5;
  --black: #4b4946;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* font-family: 'Cutive Mono', monospace;
font-family: 'Work Sans', sans-serif; */

html {
  width: 100%;
}

body {
  font-family: "Space Mono", monospace;
  font-weight: 200;
  background: #fcf2e5;
  background: var(--white);
  margin: 1px;
  color: #4b4946;
  color: var(--black);
  width: 100%;
  -webkit-animation: fadeIn 2s;
          animation: fadeIn 2s;
}

h1 {
  font-weight: 400;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 1rem;
  font-weight: 400;
}

a {
  color: #4b4946;
  color: var(--black);
}

a:hover {
  opacity: 0.4;
}

button {
  background: transparent;
  border: 1px solid #4b4946;
  border: 1px solid var(--black);
  color: #4b4946;
  color: var(--black);
  outline: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

button:hover {
  background: #4b4946;
  background: var(--black);
  color: #fcf2e5;
  color: var(--white);
}

.main-root {
  display: flex;
  align-items: stretch;
}

.nav-root {
  min-width: 300px;
  padding: 20px;
  padding-right: 100px;
}

.nav-socials svg {
  margin-right: 10px;
}

.nav-container {
  position: fixed;
}

.nav-header {
  border-bottom: 1px solid #4b4946;
  border-bottom: 1px solid var(--black);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
  padding-bottom: 20px;
}

.nav-body {
  max-width: 270px;
  padding-top: 20px;
}

.gallery-root {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.photo-grid {
  display: flex;
}

.photo-column {
  display: flex;
  flex-direction: column;
}

.photo-item {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.61803%;
  margin: 1px;
  cursor: pointer;
  transition: all 0.25s ease;
}

.photo-item:hover {
  opacity: 0.7;
}

.photo-item img {
  display: block;
  border: 0;
  width: 100%;
  height: auto;
}

.photo-detail-img {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: cover;
}

.back-arrow {
  color: #fcf2e5;
  color: var(--white);
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  margin: 20px;
  border: 1px solid #fcf2e5;
  border: 1px solid var(--white);
  transition: all 0.25s ease;
}

.back-arrow:hover {
  background: #fcf2e5;
  background: var(--white);
  color: #4b4946;
  color: var(--black);
}

/* loading spinner */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  color: #4b4946;
  color: var(--black);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 1px solid #4b4946;
  border: 1px solid var(--black);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4b4946 transparent transparent transparent;
  border-color: var(--black) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mobile-nav-root {
  display: none;
}

.mobile-nav-desc {
  display: none;
}

@media screen and (min-width: 1600px) {
  .photo-detail-img {
    width: -webkit-max-content;
    width: max-content;
    max-height: 100vh;
    object-fit: cover;
  }

  .photo-holder {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 1000px) {
  .photo-detail-root {
    margin: 0 !important;
  }
  .main-root {
    flex-direction: column;
  }

  .nav-root {
    display: none;
  }

  .mobile-nav-root {
    display: flex;
  }

  .mobile-nav-desc {
    display: block;
    margin: 20px;
    padding-top: 40px;
  }

  .nav-container {
    position: relative;
    background: #fcf2e5;
    background: var(--white);
    width: 100%;
    padding: 10px 20px;
    margin: 0;
  }

  .nav-socials {
    padding: 0;
    margin: 0;
  }

  .nav-header {
    min-height: unset;
    border: none;
  }

  .nav-header h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
}

